.App {
  text-align: center;
  height: 100%;
  width: 100%;
}

#server-status-container {
  height: calc(90vh - 56px);
  width: 100%;
  border: 0;
  padding: 0;
  margin: 0;
}