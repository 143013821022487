.serversTable {
  .role+.role {
    margin-left: 1rem;
  }

  .idle {
    background-color: azure;
  }

  .fallback {
    background-color: beige;
  }
}